import axios from 'axios'
import Options from '@/conf/Options'
import store from '@/conf/store'

function generateRequestId() {
    const timestamp = Date.now().toString(36) // Base36 timestamp
    const randomPart = Math.random().toString(36).substring(2, 8) // Random string
    return `${timestamp}-${randomPart}`
  }

function createAxiosInstance() {
    const instance = axios.create({
        baseURL: Options.baseUrl,
        responseType: "json",
        responseEncoding: 'utf-8',
        headers: {
            "Authorization": "Bearer " + store.state.accessToken,
            "version": Options.version,
            "exotica-trace-id": generateRequestId()
        }
    });
    return instance;
}

function createAxiosStreamInstance() {
    const instance = axios.create({
        baseURL: Options.baseUrl,
        responseType: "blob",
        headers: {
            "Authorization": "Bearer " + store.state.accessToken,
            "version": Options.version
        }
    });
    return instance;
}

function retrieveOrderStatusList(data) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orders/status/" + data.storeId + (data.typeId ? "/type/" + data.typeId : "")
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderRestrictedStatusList(data) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orders/status/" + data.storeId + (data.typeId ? "/type/" + data.typeId : "") + "/restricted"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveStatusBranchDistribution(data) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/dashboard/statusBranchDistribution",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveRoleList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/users/roles"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveGenderList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/gender"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrievePhoneTypeList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/phoneType"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveStoreList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/users/stores"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveLineOfProductList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/lineOfProduct"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveDriverCompanyList(filter) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/drivers/companies",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveSeason(seasonId) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/highSeasons/highSeason/" + seasonId
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveSpecialInstructionOptionList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orders/specialInstruction"
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCountryList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/country"
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveRegionList(countryCode) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/region/" + countryCode
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCityList(regionId) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/city/" + regionId
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCurrencyList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/currency"
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}


function retrieveVehicleTypeList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/vehicleType"
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrievePaymentMethodList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/paymentMethod"
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}


function retrieveBranchList(storeId) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/orders/branches/" + storeId
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveFridgeList(filter) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/fridges/fridge",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveWrappingAreaList(filter) {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/wrappingAreas/wrappingArea",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveFridgeTypeList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/fridges/fridge/type"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCurrentUserInfo() {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/users/0"
        }).then(response => {
            var loggedinUser = response.data;
            resolve(loggedinUser);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveUserList(filter) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/users",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCustomerList(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/customers" + data.typeUrl,
            params: {
                "data": encodeURI(JSON.stringify(data.filter))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveUser(id) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/users/" + id
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCustomer(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/customers/" + data.customerId + "/type/" + data.typeId
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveDriverList(filter) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/drivers",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}


function retrieveOrders(filter) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orders",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrder(orderId) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/orders/" + orderId,
            params: {
                "data": encodeURI(JSON.stringify(orderId))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderProducts(orderId) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/products/order/" + orderId,
            params: {
                "data": encodeURI(JSON.stringify(orderId))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveActivityLogs(orderId) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orders/activityLogs/" + orderId,
            params: {
                "data": encodeURI(JSON.stringify(orderId))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveHighSeasonsList(filter) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/highSeasons/highSeason",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveHighSeasonDashboard(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        if (data.lineOfProductId == null)
            instance({
                method: "get",
                url: "/dashboard/highSeason/" + data.seasonId + (data.zeroOrdersExcluded ? "/zeroOrdersExcluded" : "")
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        else
            instance({
                method: "get",
                url: "/dashboard/highSeason/" + data.seasonId + "/lineOfProduct/" + data.lineOfProductId + (data.zeroOrdersExcluded ? "/zeroOrdersExcluded" : "")
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });

    });
}

function retrieveDashboardHighSeasonStatuses(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/dashboard/highSeason/status",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveProductsList(filter) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/products",
            params: {
                "data": encodeURI(JSON.stringify(filter))
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveFridgeProducts(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/highSeasons/highSeason/" + data.seasonId + "/fridge/" + data.fridgeId
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveCompanyInfo(id) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/drivers/company/" + id
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveFridgeInfo(id) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/fridges/fridge/" + id,
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveWrappingAreaInfo(id) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/wrappingAreas/wrappingArea/" + id,
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function retrieveProductLineOfProduct(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "get",
            url: "/highSeasons/highSeason/" + data.highSeasonId + "/product/" + data.sku
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });

    });
}

function updateOrderAddress(orderId, address) {
    return new Promise((resolve, reject) => {

        const instance = createAxiosInstance();
        instance({
            method: "patch",
            url: "/customers/" + orderId,
            data: address
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function assignOrderToBranch(orderIds, branchId) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "patch",
            url: "/orders/branches/" + branchId,
            data: orderIds
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateUser(userData) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "patch",
            url: "/users",
            data: userData
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateCustomer(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "patch",
            url: "/customers/" + data.customerId + "/type/" + data.typeId,
            data: data.customer
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateSpecialInstruction(orderId, specialInstruction) {
    return new Promise((resolve, reject) => {

        const instance = createAxiosInstance();
        instance({
            method: "patch",
            url: "/orders/" + orderId,
            data: specialInstruction
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateDeliveryDate(orderId, deliverydate) {
    return new Promise((resolve, reject) => {

        const instance = createAxiosInstance();
        instance({
            method: "patch",
            url: "/orders/" + orderId,
            data: deliverydate
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateOrderStatus(orderId, status) {
    return new Promise((resolve, reject) => {

        const instance = createAxiosInstance();

        instance({
            method: "patch",
            url: "/orders/" + orderId,
            data: status
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateGiftMessage(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "patch",
            url: "/orders/" + data.orderId,
            data: data.giftMessage
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function printJob(printData) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();
        instance({
            method: "post",
            url: "/printjobs",
            data: printData
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

function createUser(user) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/users",
            data: user
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function createOrder(order) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/orders",
            data: order
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function createCustomer(customer) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/customers",
            data: customer
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function addOrderToFridge(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/orders/order/" + data.orderId + "/fridge/" + data.fridgeId
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}
function assignOrdersToDriver(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/drivers/driver/" + data.driverId + "/order/vehicleType/" + data.vehicleTypeId,
            data: data.orderIds
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateOrdersDeliveryStatus(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/orders/orderDelivery",
            data: data
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function createHighSeason(highSeason) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/highSeasons/highSeason",
            data: highSeason
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function createFridge(fridge) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/fridges/fridge",
            data: fridge
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function createWrappingArea(wrappingArea) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/wrappingAreas/wrappingArea",
            data: wrappingArea
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function addProductsToWrappingArea(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/highSeasons/wrappingArea",
            data: data
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function addProductsToFridge(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "fridges/fridge/" + data.fridgeId + "/products",
            data: data.products
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateHighSeason(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "put",
            url: "/highSeasons/highSeason/" + data.seasonId,
            data: data.highSeason
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function addCompany(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/drivers/company",
            data: data
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function registerDriver(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/drivers/driver",
            data: data
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateDriverInfo(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "put",
            url: "/drivers/driver/" + data.id,
            data: data.driver
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateUserInfo(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "patch",
            url: "/users/user/" + data.id,
            data: data.user
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateCompanyInfo(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "put",
            url: "/drivers/company/" + data.id,
            data: data.company
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateFridgeInfo(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "put",
            url: "/fridges/fridge/" + data.id,
            data: data.fridge
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateFridgeProductQuantity(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "patch",
            url: "/fridges/fridge/" + data.id + "/product",
            data: data.product
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function updateWrappingAreaInfo(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "put",
            url: "/wrappingAreas/wrappingArea/" + data.id,
            data: data.wrappingArea
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveDriver(id) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/drivers/driver/" + id
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveSalesByProductReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/sales/product",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveSalesByProductReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/sales/product/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderDeliveryReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/order/delivery",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderDeliveryReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/order/delivery/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrdersReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/order/order",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrdersReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/order/order/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveDeliveryCostReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/order/deliveryCost",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveDeliveryCostReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/order/deliveryCost/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderDriverReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/order/driver",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderDriverReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/order/driver/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCustomerListReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/customer/customer",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCustomerListReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/customer/customer/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCustomerOrderReport(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/report/customer/order",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveCustomerOrderReportExcelFile(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/report/customer/order/export",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function checkOrder(orderId) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orders/orderCheck/" + orderId
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function checkOrderDriver(orderId) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/drivers/orderCheck/" + orderId
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}


function retrievePriceCheckerProducts(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/productChecker/products",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrievePriceCheckerProductsExcelFile() {
    return new Promise((resolve, reject) => {
        const instance = createAxiosStreamInstance();

        instance({
            method: "get",
            url: "/productChecker/products/export"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function importPriceCheckerProducts(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "post",
            url: "/productChecker/product/import",
            headers: {
                "Authorization": "Bearer " + store.state.accessToken,
                "version": Options.version,
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: function (progressEvent) {
                let progress = Math.round(progressEvent.loaded * 10000 / progressEvent.total) / 100;
                store.commit('updateUploadProgress', progress.toFixed(2));
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function removePriceCheckerProduct(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "delete",
            url: "/productChecker/product/" + encodeURI(data.barcode) + "/"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}


function removeAllProducts() {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "delete",
            url: "/productChecker/products"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrievePriceCheckerActivityLogs(data) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/productChecker/activityLog",
            params: {
                "data": encodeURI(JSON.stringify(data))
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveAddressesByPhoneNumber(phoneNumber) {
    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/customers/addresses/phone/" + phoneNumber,
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

function retrieveOrderTypeList() {

    return new Promise((resolve, reject) => {
        const instance = createAxiosInstance();

        instance({
            method: "get",
            url: "/orderType"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export default {
    addCompany,
    addOrderToFridge,
    addProductsToFridge,
    addProductsToWrappingArea,
    assignOrdersToDriver,
    assignOrderToBranch,
    checkOrder,
    checkOrderDriver,
    createCustomer,
    createFridge,
    createHighSeason,
    createOrder,
    createUser,
    createWrappingArea,
    importPriceCheckerProducts,
    printJob,
    removePriceCheckerProduct,
    removeAllProducts,
    registerDriver,
    retrieveActivityLogs,
    retrieveAddressesByPhoneNumber,
    retrieveBranchList,
    retrieveCityList,
    retrieveCompanyInfo,
    retrieveCountryList,
    retrieveCurrencyList,
    retrieveCurrentUserInfo,
    retrieveCustomer,
    retrieveCustomerList,
    retrieveCustomerListReport,
    retrieveCustomerListReportExcelFile,
    retrieveCustomerOrderReport,
    retrieveCustomerOrderReportExcelFile,
    retrieveDashboardHighSeasonStatuses,
    retrieveDriver,
    retrieveDriverCompanyList,
    retrieveDriverList,
    retrieveFridgeInfo,
    retrieveFridgeList,
    retrieveFridgeProducts,
    retrieveFridgeTypeList,
    retrieveGenderList,
    retrievePhoneTypeList,
    retrieveHighSeasonDashboard,
    retrieveHighSeasonsList,
    retrieveLineOfProductList,
    retrieveOrder,
    retrieveOrderProducts,
    retrieveOrderRestrictedStatusList,
    retrieveOrderTypeList,
    retrieveOrders,
    retrieveOrderStatusList,
    retrievePaymentMethodList,
    retrievePriceCheckerActivityLogs,
    retrievePriceCheckerProducts,
    retrievePriceCheckerProductsExcelFile,
    retrieveProductLineOfProduct,
    retrieveProductsList,
    retrieveRegionList,
    retrieveSalesByProductReport,
    retrieveSalesByProductReportExcelFile,
    retrieveOrderDeliveryReport,
    retrieveOrderDeliveryReportExcelFile,
    retrieveOrderDriverReport,
    retrieveOrderDriverReportExcelFile,
    retrieveOrdersReport,
    retrieveOrdersReportExcelFile,
    retrieveDeliveryCostReport,
    retrieveDeliveryCostReportExcelFile,
    retrieveRoleList,
    retrieveSeason,
    retrieveSpecialInstructionOptionList,
    retrieveStatusBranchDistribution,
    retrieveStoreList,
    retrieveUserList,
    retrieveUser,
    retrieveVehicleTypeList,
    retrieveWrappingAreaList,
    retrieveWrappingAreaInfo,
    updateCompanyInfo,
    updateDeliveryDate,
    updateDriverInfo,
    updateFridgeInfo,
    updateFridgeProductQuantity,
    updateHighSeason,
    updateOrderAddress,
    updateOrderStatus,
    updateGiftMessage,
    updateOrdersDeliveryStatus,
    updateSpecialInstruction,
    updateCustomer,
    updateUser,
    updateUserInfo,
    updateWrappingAreaInfo
}